.ReactModal__Overlay{
    z-index: 13000 !important;
}
.Modal{
    width: 100vw;
    height: 100vh;
    position:fixed;
    top:0;
    left:0;
  
  
    background-color: #fff;
}

.Modal .button-close-search-modal{
   font-size: 25px;
  
}

.Modal .header-modal-search{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 1.5rem;
    border-bottom: 1px solid rgb(229, 229, 229);
    min-height: 4rem;
   
}

.Modal .header-modal-search p{
    margin-bottom: 0;
    font-family: "Outfit", sans-serif;
    text-transform: uppercase;
    flex:1;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
}

.Modal .input-rent-sale-wrapper{
    position: relative;
    font-family: "Outfit", sans-serif;
    min-height: 3rem;
    display: flex;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(229, 229, 229);
}

.Modal .input-rent-sale-wrapper label{
    width: 33%;
  
    display: flex;
    align-items: center;
        justify-content: center;
        position: relative;
        border-left: 1px solid rgb(229, 229, 229); 
}

.Modal .input-rent-sale-wrapper input{
    position:absolute;
    top:50%;
    left:50%;
    visibility: hidden;
   
    
}
.Modal .input-rent-sale-wrapper span::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 10000;
    transform: translateX(-50%);
    transition: width 0.4s cubic-bezier(0, 0.65, 0.45, 1.06) 0s;
    width: 0;
    height: 2px;
    background: rgb(0, 0, 0);
}



.Modal .input-rent-sale-wrapper label.activeRent .rent-span::after{
    width: calc(100%);
}

.Modal .input-rent-sale-wrapper label.activeSale .sale-span::after{
    width: calc(100%);
}
.rent-span{
font-size: 14px;
}
.sale-span{
    font-size: 14px;
}
.select-half{
    text-align: center;
    border:none;
    font-family: "Outfit", sans-serif;
    font-size:14px;
    text-transform: uppercase;
    width: 100%;
   
}
.select-half option {
  font-size: 12px;
}

.Modal .search-wrapper{
    position: relative;
    font-family: "Outfit", sans-serif;
    min-height: 3.3rem;
    display: flex;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(229, 229, 229);
    width:100%;
    overflow: hidden;
    padding-right:1rem;
    padding-left: 1rem;
    padding-top:.5rem;
    padding-bottom: .5rem;
    
}

.Modal .search-wrapper input{
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    border:none;
    


}

.Modal .search-wrapper input::-webkit-input-placeholder  { 
    font-family: "Outfit", sans-serif;
    font-size:18px;
    font-weight: 300;
}
.Modal .search-wrapper input:-moz-placeholder {
    font-family: "Outfit", sans-serif;
    font-size:18px;
    font-weight: 300;

}
.button-send-search-wrapper{
    width:100% !important;
    text-align: center;
    height:43px;
}
.button-send-search-wrapper button{
    background-color: transparent;
    width:100%;
    height: 100%;
    border: 1px solid rgb(229, 229, 229);
    text-transform: uppercase;
    font-family: "Outfit", sans-serif;
    color:rgb(57, 97, 134);
    
}
.subtitle-search-list{
    font-family: "Outfit", sans-serif;
    font-weight: 500;
    font-size:11px;
    color:rgb(100, 100, 100);
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: rgb(245, 245, 245);
    line-height: 12px;
    padding: 0px 14px;
    height: 24px;

    display: flex;
    align-items: center;
}
.city-search{
    font-family: "Outfit", sans-serif;
    font-weight: 200;
    font-size:13px;
    color:rgb(100, 100, 100);
    line-height: 39px;
    letter-spacing: 1px;
    line-height: 12px;
    padding: 0.5rem 14px;
    border:none;
    outline: none;
}
.district-search{
    font-family: "Outfit", sans-serif;
    font-weight: 200;
    font-size:13px;
    color:rgb(100, 100, 100);
    line-height: 39px;
    letter-spacing: 1px;
    line-height: 12px;
    padding: 0.5rem 0 0.5rem 14px;

    border:none;
    outline: none;
    overflow: hidden;
    
}
.district-li-search{
    font-family: "Outfit", sans-serif;
    font-weight: 200;
    font-size:13px;
    color:rgb(100, 100, 100);
    line-height: 39px;
    letter-spacing: 1px;
    line-height: 12px;
    padding: 0.5rem 0 0.5rem 0;
    border:none;
    outline: none;
    overflow: hidden;
    
   
}
.button-loading{
    position: relative;
    margin-bottom: 0;
}

.district-wrapper{
    display: flex;
    align-items: center;
    justify-content: left;

   
}
